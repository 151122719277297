import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import NavbarLower from "../../components/App/NavbarLower"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from '../../components/Common/PageBanner'
import BlogSidebar from '../../components/BlogContent/BlogSidebar'




const BlogPage = ({ data }) => (
  <Layout>
    <NavbarLower />
    <PageBanner
                pageTitle="Blog Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Blog Details" 
            /> 
    <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                            </div>
                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <i className='bx bx-folder-open'></i>
                                            <span>Category</span>
                                            {data.microcmsBlog.category.name}
                                           
                                        </li>

                                        <li>
                                            <i className='bx bx-calendar'></i>
                                            <span>Last Updated</span>
                                            {data.microcmsBlog.publishedAt}
                                        </li>
                                    </ul>
                                </div>

                                <h3>{data.microcmsBlog.title}</h3>

                                <article>
                                {parse(data.microcmsBlog.content)}
                                </article>
                            </div>




                            <div className="tracer-post-navigation">

        

                            </div>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar />
                    </div>
                </div>
            </div>
        </section>    

    <Footer />
  </Layout>
)

export default BlogPage

export const query = graphql`
  query($id: String!) {
    microcmsBlog(id: { eq: $id }) {
      blogId
      title
      content
      publishedAt(formatString: "YYYY/MM/DD")
      category {
        name
      }      
    }
  }
`